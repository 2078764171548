import {
  Form,
  Button,
  Typography,
  Modal,
  Row,
  Col,
  Divider,
  message,
  Upload,
} from "antd";
import {
  SaveOutlined,
  FileAddOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import Input from "antd/lib/input/Input";
import { useVmsSettings } from "./store";
import { useMutation } from "react-query";

import { createVmsSettings, editVmsSettings } from "../../api";
import handleDataMutation from "../../helper/dataMutation";
const { Title } = Typography;
const { Dragger } = Upload;

export const PureCreate = () => {
  const {
    isCreateModal,
    setIsCreateModal,

    id,
    link,
    version,

    setVersion,
    setLink,
    setId,
  } = useVmsSettings();

  const resetState = () => {
    setVersion(null);
    setId(null);
    setLink(null);
  };

  const { mutate, isLoading } = useMutation(createVmsSettings, {
    onSuccess: (data) => {
      handleDataMutation({
        mutatedData: data,
        queryClientKey: "VmsSettings",
        setterList: [() => setIsCreateModal(false)],
        resetFunc: resetState,
      });
    },
    onError: (e) => {
      message.error(e.message);
    },
  });
  const { mutate: editMutate, isLoading: editLoading } = useMutation(
    editVmsSettings,
    {
      onSuccess: (data) => {
        handleDataMutation({
          mutatedData: data,
          queryClientKey: "VmsSettings",
          setterList: [() => setIsCreateModal(false)],
          resetFunc: resetState,
        });
      },
      onError: (e) => {
        message.error(e.message);
      },
    }
  );

  let fileProps = {
    name: "file",
    multiple: false,
    maxCount: 1,
    disabled: isLoading ? true : false,
    beforeUpload: (file) => {
      return false;
    },
  };

  const onFinish = (values) => {
    if (id) {
      values.id = id;
      editMutate(values);
    } else {
      values.file = values?.file?.fileList[0]?.originFileObj;
      mutate(values);
    }
  };

  return (
    <Modal
      title={<Title level={5}>{id ? "Edit" : "New"} VMS Settings</Title>}
      className="app-modal"
      centered
      visible={isCreateModal}
      onCancel={() => {
        resetState();
        setIsCreateModal(false);
      }}
      footer={false}
      destroyOnClose={true}
      width={400}
    >
      <Form
        requiredMark={"optional"}
        name="basic"
        initialValues={{ remember: true, link, version }}
        onFinish={onFinish}
        autoComplete="off"
        layout="vertical"
      >
        <Row gutter={[20, 20]}>
          {id ? (
            <Col span={24}>
              <Form.Item label="Link" name="link" rules={[{ required: true }]}>
                <Input placeholder="Link" />
              </Form.Item>
            </Col>
          ) : (
            <Col span={24}>
              <Form.Item
                label="VMS File"
                name="file"
                rules={[{ required: true }]}
              >
                <Dragger {...fileProps}>
                  {!isLoading ? (
                    <>
                      <p className="ant-upload-drag-icon">
                        <FileAddOutlined />
                      </p>
                      <p className="ant-upload-text">VMS APK</p>
                      <p className="ant-upload-hint">
                        Drag File to this area to upload
                      </p>
                    </>
                  ) : (
                    <>
                      <p className="ant-upload-text">Uploading File ...</p>
                      <LoadingOutlined />
                      <p className="ant-upload-hint">
                        please wait this may take a while
                      </p>
                    </>
                  )}
                </Dragger>
              </Form.Item>
            </Col>
          )}
          <Col span={24}>
            <Form.Item
              label="Version"
              name="version"
              rules={[{ required: true }]}
            >
              <Input placeholder={"Version"} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Divider />
            <Form.Item>
              <Button
                loading={isLoading || editLoading}
                type="primary"
                htmlType="submit"
                icon={<SaveOutlined />}
              >
                Save
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
