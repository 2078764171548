import { useState, useMemo } from "react";
import {
  Table,
  Tag,
  Popover,
  Typography,
  Alert,
  Pagination,
  Popconfirm,
  message,
  Button,
} from "antd";
import { useQuery, useMutation } from "react-query";
import { DeleteOutlined } from "@ant-design/icons";
import { pageSize, getVendorVoucher, DeleteFromVoucher } from "../../../api";

import handleDataMutation from "../../../helper/dataMutation";
const { Text } = Typography;

export const VendorTable = ({ id, filter }) => {
  const [page, setPage] = useState(1);

  const columns = useMemo(
    () => [ 
    {
      title: "#",
      dataIndex: "id",
      key: "id",
    },

    {
      title: "Name",
      dataIndex: "arName",
      key: "arName",
      render: (text, row) => (
        <Popover
          placement="topLeft"
          content={<Text type="secondary">{row.description}</Text>}
        >
          <Text style={{ fontWeight: "bold" }}>{text}</Text>
        </Popover>
      ),
    },

    {
      title: "Region",
      dataIndex: "city",
      key: "city",
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      render: (text) => (
        <Text type="secondary" style={{ fontSize: 12 }}>
          {text}
        </Text>
      ),
    },

    {
      title: "Owner Name",
      dataIndex: "ownerName",
      key: "ownerName",
    },
    {
      title: "Owner Phone",
      dataIndex: "resturantMgmtPhone",
      key: "resturantMgmtPhone",
      render: (text) => <Text style={{ fontSize: 12 }}>{text}</Text>,
    },
    {
      title: "Resturant Phone",
      dataIndex: "resturantPhone",
      key: "resturantPhone",
      render: (text) => <Text style={{ fontSize: 12 }}>{text}</Text>,
    },
    {
      title: "Status Code",
      dataIndex: "StatusCode",
      key: "StatusCode",
      render: (val) => <Tag color="geekblue">{val}</Tag>,
    },
    {
      title: "Rank",
      dataIndex: "rank",
      key: "rank",
    },
    {
      title: "",
      key: "id",
      render: (row) => (
        <Popconfirm
          title="Are you sure to delete this client?"
          onConfirm={() =>
            mutate({
              id,
              vendorID: row.id,
            })
          }
          okText="Yes"
          cancelText="No"
          placement="rightBottom"
        >
          <Button size="small" danger icon={<DeleteOutlined />} />
        </Popconfirm>
      ),
    },
  ],[])

  const { mutate, isLoading: deleteLoading } = useMutation(
    (values) => DeleteFromVoucher("vendor", values),
    {
      onSuccess: (data) => {
        handleDataMutation({
          mutatedData: data,
          queryClientKey: ["vouchers", "clientsVoucher", "vendorsVoucher"],
        });
      },
      onError: (e) => {
        message.error(e.message);
      },
    }
  );

  const {
    isLoading: loading,
    error,
    data,
  } = useQuery(["vendorsVoucher", filter, page, id], getVendorVoucher, {
    refetchOnWindowFocus: false,
    retry: 2,
  });

  if (error) return <Alert message={error.message} type="error" showIcon />;

  if (!loading && !data.success)
    return <Alert message={data.msg} type="error" showIcon />;

  return (
    <>
      <Table
        loading={loading || deleteLoading}
        columns={columns}
        dataSource={data ? data?.data?.records : []}
        size="small"
        rowKey={(record) => record.id}
        pagination={false}
      />
      <br />
      <Pagination
        onChange={(val) => setPage(val)}
        current={page}
        defaultPageSize={pageSize}
        total={data && data?.data?.total}
        showSizeChanger={false}
      />
    </>
  );
};
