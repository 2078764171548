import { Button, Modal, Typography, message } from "antd";
import dayjs from "dayjs";

import { useVendor } from "./store";
import { CustomeSchedule } from "../../comonents/global";
import { useMutation } from "react-query";

import { SaveOutlined } from "@ant-design/icons";
import { updateData } from "../../api";
import handleDataMutation from "../../helper/dataMutation";
const { Title } = Typography;

export const WorkingSchedule = () => {
  const {
    isSchedual,
    setIsSchedule,
    workingSchedule,
    setWorkingSchedule,
    row,
    setRow,
  } = useVendor();

  const { mutate, isLoading } = useMutation(
    (e) => updateData("vendor/workingsechdule", e),
    {
      onSuccess: (data) => {
        handleDataMutation({
          mutatedData: data,
          queryClientKey: "vendors",
          setterList: [() => setIsSchedule(false), () => setRow(null)],
        });
      },
      onError: (e) => {
        message.error(e.message);
      },
    }
  );

  const handleSave = async () => {
    let isError = false;
    await workingSchedule.map((el) => {
      el.workingTime.map((time) => {
        if (time.from == null) {
          isError = true;
        }
      });
    });

    if (isError) {
      message.error("There is Empty Cell !");
    } else {
      let _workingSchedule = JSON.stringify(
        workingSchedule.map((t) => {
          t.workingTime = t.workingTime.map((w) => ({
            from: dayjs(w.from).toDate(),
            to: dayjs(w.to).toDate(),
          }));
          return { ...t };
        })
      );

      let data = {
        id: row.id,
        workingSchedule: _workingSchedule,
      };

      mutate(data);
    }
  };

  return (
    <Modal
      title={
        <Title style={{ marginBottom: 0 }} level={5}>
          Working Days
        </Title>
      }
      className="app-modal"
      centered
      visible={isSchedual}
      onCancel={() => setIsSchedule(false)}
      footer={false}
      destroyOnClose={true}
      width={"90vw"}
      bodyStyle={{ paddingTop: 10, minHeight: "75vh" }}
    >
      <CustomeSchedule
        value={workingSchedule}
        onChange={(val) => {
          setWorkingSchedule(val);
        }}
        fromInit={12}
      />

      <Button
        loading={isLoading}
        style={{ position: "absolute", bottom: 35, left: 35 }}
        type="primary"
        onClick={handleSave}
        icon={<SaveOutlined />}
      >
        Save
      </Button>
    </Modal>
  );
};
