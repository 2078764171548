import { useState, useEffect , useMemo } from "react";
import {
  Table,
  Typography,
  Button,
  message,
  Alert,
  Space,
  Pagination,
  Switch,
  Tooltip,
  Popconfirm,
  Tag,
} from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  HistoryOutlined,
} from "@ant-design/icons";
import { useQuery, useMutation } from "react-query";

import {
  getProducts,
  removeProduct,
  pageSize,
  updateProductStatus,
} from "../../api";

import { useProduct } from "./store";
import { useSearchParams, NavLink } from "react-router-dom";
import authFunc from "../../helper/auth";

import handleDataMutation from "../../helper/dataMutation";
const { Text } = Typography;

export const PuerTable = () => {

  const {
    setDescription,
    setId,
    setIsCreateModal,
    setName,
    queryKey,
    setCategoryID,
    setStatusHistory,
    setIsHistoryModal,
    setBrandID,
    setPriority,
    setSubCategoryID,
    setPage,
    page,
    categoryID,
    subCategoryID,
    brandID,
  } = useProduct();

  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedID, setSelectedID] = useState(null);

  useEffect(() => {
    if (searchParams.get("page_num")) {
      setPage(searchParams.get("page_num"));
    } else {
      setPage(1);
    }
  }, [searchParams.get("page_num")]);

  const handleEdit = (row) => {
    setId(row.id);
    setName(row.name);
    setDescription(row.description);
    setCategoryID(row.category.parentID);
    setSubCategoryID(row.categoryID);
    setBrandID(row.brandID);
    setPriority(row.priority);
    setIsCreateModal(true);
  };

  const handleHisoryModal = (row) => {
    setStatusHistory(row.statusHistory);
    setIsHistoryModal(true);
  };

  const { mutate: editStatusMutate, isLoading: editStatusLoading } =
    useMutation(updateProductStatus, {
      onSuccess: (data) => {
        setSelectedID(null);
        handleDataMutation({
          mutatedData: data,
          queryClientKey: "products",
        });
      },
      onError: (e) => {
        setSelectedID(null);
        message.error(e.message);
      },
    });

  const handleChangeActive = (row) => {
    setSelectedID(row.id);
    let data = {
      id: row.id,
      status: !row.status,
    };
    editStatusMutate(data);
  };

  const columns = useMemo(
    () => [ 
    {
      title: "#",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Product Name",
      dataIndex: "name",
      key: "name",
      render: (text) => <Text style={{ fontWeight: "bold" }}>{text}</Text>,
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      render: (val) => <Tag>{val.parentName}</Tag>,
    },
    {
      title: "Sub Category",
      dataIndex: "category",
      key: "category",
      render: (val) => <Tag color={"purple"}>{val.name}</Tag>,
    },
    {
      title: "Brand",
      dataIndex: "brand",
      key: "brand",
      render: (val) => <Tag color={"geekblue"}>{val.name}</Tag>,
    },
    {
      title: "Priority",
      dataIndex: "priority",
      key: "priority",
      render: (val) => <Tag color={"red"}>{val}</Tag>,
    },
    {
      title: "Status",
      key: "id",
      render: (row) => (
        <Switch
          disabled={authFunc("PRODUCT_UPDATE") ? false : true}
          loading={selectedID === row.id && editStatusLoading}
          checked={row.status}
          onChange={(state) => handleChangeActive(row)}
        />
      ),
    },
    {
      title: "Actions",
      key: "id",
      dataIndex: "id",
      render: (id, row) => (
        <Space>
          <NavLink to={`/products/${id}/productVariation`}>
            <Tooltip placement="top" title="view product variation">
              <Button size="small">Products Variation</Button>
            </Tooltip>
          </NavLink>
          <Button
            onClick={() => handleHisoryModal(row)}
            size="small"
            icon={<HistoryOutlined />}
          />
          {authFunc("PRODUCT_UPDATE") && (
            <Button
              onClick={() => handleEdit(row)}
              size="small"
              icon={<EditOutlined />}
            />
          )}
          {authFunc("PRODUCT_DELETE") && (
            <Popconfirm
              title="Are you sure to delete this product?"
              onConfirm={() => mutate(id)}
              okText="Yes"
              cancelText="No"
            >
              <Button size="small" danger icon={<DeleteOutlined />} />
            </Popconfirm>
          )}
        </Space>
      ),
    },
  ],[])

  const { mutate } = useMutation(removeProduct, {
    onSuccess: (data) => {
      handleDataMutation({
        mutatedData: data,
        queryClientKey: "products",
      });
    },
    onError: (e) => {
      message.error(e.message);
    },
  });

  const {
    isLoading: loading,
    error,
    data,
  } = useQuery(
    ["products", queryKey, page, categoryID, subCategoryID, brandID],
    getProducts,
    {
      refetchOnWindowFocus: false,
      retry: 2,
    }
  );



  if (error || (!data?.success && data?.data?.err)) return (
      <Alert
        message={data?.data?.err || error?.message}
        type="error"
        showIcon
      />);
 return(
    <>
      <Table
        loading={loading}
        columns={columns}
        dataSource={data ? data?.data?.records : []}
        size="small"
        rowKey={(record) => record.id}
        pagination={false}
      />
      <br />
      <Pagination
        onChange={(val) => {
          searchParams.set("page_num", val);
          setSearchParams(searchParams);
          setPage(val);
        }}
        current={Number(searchParams.get("page_num")) || page}
        defaultPageSize={pageSize}
        total={data && data?.data?.total}
        showSizeChanger={false}
      />
    </>
  );
};
