import { useState, useEffect, useMemo } from "react";
import {
  Table,
  Typography,
  Button,
  message,
  Alert,
  Space,
  Pagination,
  Switch,
  Popconfirm,
  Tag,
} from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { useQuery, useMutation } from "react-query";
import {
  getProductsByVendorID,
  removeData,
  pageSize,
  editVendorProductVariationStatus,
} from "../../api";
import handleDataMutation from "../../helper/dataMutation";
import { useVendorProductVariation } from "./store";
import { useSearchParams, useParams } from "react-router-dom";
import dayjs from "dayjs";
import authFunc from "../../helper/auth";
import numberForamt from "../../helper/numberFormat";

const { Text } = Typography;

export const PuerTable = () => {
  const [page, setPage] = useState(1);
  const {
    setId,
    setIsCreateModal,
    setPrice,
    setAvailableQty,
    setPriority,
    setProductVariationID,
    queryKey,
    setBarcode,
    status,
  } = useVendorProductVariation();

  const [searchParams, setSearchParams] = useSearchParams();
  const { vendorID } = useParams();

  useEffect(() => {
    if (searchParams.get("page_num")) {
      setPage(searchParams.get("page_num"));
    } else {
      setPage(1);
    }
  }, [searchParams.get("page_num")]);

  const handleEdit = (row) => {
    setId(row.id);
    setPrice(row.price);
    setAvailableQty(row.availableQty);
    setPriority(row.priority);
    setProductVariationID(row.productVariationID);
    setIsCreateModal(true);
    setBarcode(row.barcode);
  };

  const { mutate: editMutate, isLoading: editLoading } = useMutation(
    editVendorProductVariationStatus,
    {
      onSuccess: (data) => {
        handleDataMutation({
          mutatedData: data,
          queryClientKey: "vendorProductVariation",
        });
      },
      onError: (e) => {
        message.error(e.message);
      },
    }
  );

  const { mutate } = useMutation(
    (val) => removeData("vendor/product/variation", val),
    {
      onSuccess: (data) => {
        handleDataMutation({
          mutatedData: data,
          queryClientKey: "vendorProductVariation",
        });
      },
      onError: (e) => {
        message.error(e.message);
      },
    }
  );

  const handleChangeActive = (row) => {
    {
      editMutate({
        id: row.id,
        status: !row.status,
      });
    }
  };

  const columns = useMemo(
    () => [
      {
        title: "#",
        dataIndex: "id",
        key: "id",
      },
      {
        title: "Product",
        dataIndex: "product",
        key: "product",
        key: "unit",
        render: (val) => <b>{val}</b>,
      },
      {
        title: "Vartiation",
        dataIndex: "name",
        key: "name",
        render: (val) => (val ? <Tag color="purple">{val}</Tag> : ". . ."),
      },
      {
        title: "On PWA",
        key: "id",
        render: (row) => `${row?.product} ${row?.name || ""}`,
      },
      {
        title: "Size",
        dataIndex: "size",
        key: "size",
      },
      {
        title: "Unit",
        dataIndex: "unit",
        key: "unit",
        render: (val) => val || ". . .",
      },
      {
        title: "Price",
        dataIndex: "price",
        key: "price",
        render: (val) => <b>{numberForamt(val)}</b>,
      },
      {
        title: "Available Qty",
        dataIndex: "availableQty",
        key: "availableQty",
      },
      {
        title: "Priority",
        dataIndex: "priority",
        key: "priority",
        render: (val) => <Tag color={"red"}>{val}</Tag>,
      },
      {
        title: "Creation Date",
        dataIndex: "created_at",
        key: "created_at",
        render: (date) => (
          <Text type="secondary">{dayjs(date).format("MMMM D, YYYY")}</Text>
        ),
      },
      {
        title: "Status",
        key: "id",
        render: (row) => (
          <Switch
            loading={editLoading}
            checked={row.status}
            onChange={(state) => handleChangeActive(row)}
          />
        ),
      },
      {
        title: "Actions",
        key: "id",
        dataIndex: "id",
        render: (id, row) => (
          <Space>
            {authFunc("PRODUCT_UPDATE") && (
              <Button
                onClick={() => handleEdit(row)}
                size="small"
                icon={<EditOutlined />}
              />
            )}
            {authFunc("PRODUCT_DELETE") && (
              <Popconfirm
                title="Are you sure to delete this product variation?"
                onConfirm={() => mutate(id)}
                okText="Yes"
                cancelText="No"
              >
                <Button size="small" danger icon={<DeleteOutlined />} />
              </Popconfirm>
            )}
          </Space>
        ),
      },
    ],
    []
  );

  const {
    isLoading: loading,
    error,
    data,
  } = useQuery(
    ["vendorProductVariation", vendorID, page, 10, status, queryKey],
    getProductsByVendorID,
    {
      refetchOnWindowFocus: false,
      retry: 2,
      enabled: !!vendorID,
    }
  );

  return (
    <>
      <Table
        loading={loading}
        columns={columns}
        dataSource={data ? data?.data?.records : []}
        size="small"
        rowKey={(record) => record.id}
        pagination={false}
      />
      <br />
      <Pagination
        onChange={(val) => {
          searchParams.set("page_num", val);
          setSearchParams(searchParams);
          setPage(val);
        }}
        current={Number(searchParams.get("page_num")) || page}
        defaultPageSize={pageSize}
        total={data && data?.data?.total}
        showSizeChanger={false}
      />
    </>
  );
};
